import React from "react";
import logo from '../components/Menu/srcMenu/Logo.png';

import facebook from './srcFooter/facebook.png'
import instagram from './srcFooter/instagram.png'
import youtube from './srcFooter/youtube.png'
import tiktok from './srcFooter/tiktok.png'

import {useNavigate } from "react-router-dom";

import './Footer.css'

function Footer() {

  const navigate = useNavigate();
  const goHome = () =>{
      navigate('/enycosmic-metavers')
  }

    return(
        <footer>
          <ul className="box-redes">
            {redes.map(redSocial =>(
              <li 
              key={redSocial.id}
              >
                <a href={redSocial.enlace} ><img className="social-networks" src={redSocial.logo} alt={redSocial.alt}/></a>
              </li>
            ))}
          </ul>

          <img className="logo-Enycosmic-footer" src={logo} onClick={goHome} />
          <h2>Enycosmic 2024. Todos los derechos reservados.</h2>
          <ul className="legal-warning">
            <li>Aviso de Privacidad</li>
            <li>Información de la empresa</li>
            <li>Términos y Condiciones</li>
          </ul>
        </footer>
    )
}

const redes = [];

redes.push({
  id: redes.length,
  logo:facebook,
  alt:'Facebook',
  enlace:'https://www.facebook.com/groups/1174826680623768',
});
redes.push({
  id: redes.length,
  logo:instagram,
  alt:'Instagram',
  enlace:'https://www.instagram.com/enycosmic/',
});
redes.push({
  id: redes.length,
  logo:tiktok,
  alt:'Tiktok',
  enlace:'https://www.tiktok.com/@enycomic',
});
redes.push({
  id: redes.length,
  logo:youtube,
  alt:'Youtube',
  enlace:'https://www.youtube.com/@enycosmic4478',
});

export { Footer };