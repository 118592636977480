import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from './srcMenu/Logo.png';


function Menu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const navigate = useNavigate();
    const goHome = () =>{
        navigate('/enycosmic-metavers')
    }

    return (
        <header className="header-menu" id="main-menu">
            <img className="logo-Enycosmic" src={logo} alt="Logo Enycosmic" onClick={goHome}/>
            <ul className={isOpen ? 'open' : ''}>
                {routes.map((route, index) => (
                    <li key={index}>
                        <NavLink
                            className={({ isActive }) => {
                                if (route.class === 'acces-metavers-menu') {
                                    return isActive ? 'acces-metavers-menu acces-metavers-menu-active' : 'acces-metavers-menu';
                                } else {
                                    return isActive ? 'nav-link nav-link-active' : 'nav-link';
                                }
                            }}
                            to={route.to}
                            onClick={closeMenu} // Aquí cerramos el menú cuando se hace clic en el enlace
                        >
                            {route.text}
                        </NavLink>
                    </li>
                ))}
            </ul>
            <div className='box-menu' onClick={toggleMenu}>
                <div className={isOpen ? 'bar transform-bar1' : 'bar'}></div>
                <div className={isOpen ? 'bar transform-bar2' : 'bar'}></div>
                <div className={isOpen ? 'bar transform-bar3' : 'bar'}></div>
            </div>
        </header>
    );
}

const routes = [
    {
        to: '/home',
        text: 'Inicio',
    },
    {
        to: '/quienes-somos',
        text: 'Sobre nosotros',
    },
    {
        to: '/foro',
        text: 'Foro',
    },
    {
        to: '/donations',
        text: 'Donaciones',
    },
    {
        to: '/shooping-Metavers',
        text: 'Quiero mi bono',
        class: 'acces-metavers-menu'
    }
];

export { Menu };
