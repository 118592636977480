import { Menu } from './components/Menu/Menu';
import { Foro } from './components/Foro/Foro';
import { HomePage } from './components/HomePage';
import { Donations } from './components/Donations/Donations';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'; 
import { QuienesSomos } from './components/QuienesSomos/QuienesSomos';
import { ShoopingMetavers } from './components/ShoppingMetavers/ShoppingMetavers';
import { EnycosmicMetavers } from './components/EnycosmicMetavers/EnycosmicMetavers';
import { Footer } from './Footer/Footer';

function App() {
  return (
    <div className='body-app'>
      <HashRouter>
        <Menu />
        
        <Routes>
          <Route path='/home' element={<HomePage />} />
          <Route path='/shooping-Metavers' element={<ShoopingMetavers />} />
          <Route path='/donations' element={<Donations />} />
          <Route path='/quienes-somos' element={<QuienesSomos />} />
          <Route path='/enycosmic-metavers' element={<EnycosmicMetavers />} />
          <Route path='/foro' element={<Foro />} />

          <Route path="/" element={<Navigate to="/home" replace />} /> 
          <Route path='*' element={<p>Not found</p>} />
        </Routes>

        <Footer />

      </HashRouter>
    </div>
  );
}

export default App;
