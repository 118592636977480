import React from "react";
import './Donations.css';

function Donations() {
    return(
        <section className="one-element donations">
            <h1 className='tittle-shopp'>¡Crece con nosotros!</h1>
            <p className="text-donations">Enycosmic es una iniciativa emprendedora y queremos de todo corazón crecer. Dos personas Iniciamos con una idea, un sueño, y damos lo mejor de nosotros con el anhelo de crecer y crear oportunidades. Con tu ayuda, creceremos juntos</p>
            <a href="https://www.paypal.com/donate/?hosted_button_id=7YBTDDZXAUQCW" className="acces-donations">Quiero aportar</a>
        </section>
    );
}

export { Donations }