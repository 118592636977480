import React, { useState, useEffect } from "react";
import './EnycosmicMetavers.css';

function EnycosmicMetavers() {
    /* Contador Regresivo próximo 2024-09-22 a las 9 de la noche (inicio) */
    const calculateTimeLeft = () => {
        const difference = +new Date('2024-09-22T21:00:00') - +new Date();
        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    const timerComponents = [];
    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval}>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    /* ingreso al metaverso (inicio) */
    const startMetavers = () => {
        const accesMetavers = document.querySelector('.acces-metavers');
        if (timerComponents.length === 0) {
            window.location.href = 'https://www.jorgeisaac.website';
        } else {
            accesMetavers.textContent = 'Calma!🙈 Aún no es tiempo! 👾';
        }
    };
    /* ingreso al metaverso (fin) */

    return (
        <section className="enycosmic-metavers">
            <h1>Enycosmic Metavers</h1>
            <div className="box-presentation">
                <div className="pantalla">
                    <h2>No te pierdas la apertura del metaverso de Enycosmic</h2>
                    <p>Próximo 22 de septiembre de 2024 a las 9:00 PM</p>
                    <p className="countdown">
                        {timerComponents.length ? timerComponents : <span>¡Ya comenzó!</span>}
                    </p>
                </div>
                <div className="botones"></div>
            </div>
            <button className="acces-metavers" onClick={startMetavers}>🪐¡Comencemos Ya!🚀</button>
        </section>
    );
}

export { EnycosmicMetavers };
