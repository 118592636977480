import React, { useState } from "react";
import './HomePage.css'

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper/modules';

import press1 from './srcHomePage/Press1.svg'
import press2 from './srcHomePage/Press2.svg'
import press3 from './srcHomePage/Press3.svg'
import press4 from './srcHomePage/Press4.svg'

import bol from './srcHomePage/bol.png'
import { useNavigate } from "react-router-dom";

function HomePage () {
    const [currentIndex, setCurrentIndex] = useState(0);
    const navigate =useNavigate();

    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.activeIndex);
    };

    const handleNavigate = () =>{
        navigate('/enycosmic-metavers');
    }

    return(
        <>
            <section className="one-element font-home-page-section-one">
                <h1>Bienvenido a Enycosmic Metaverse</h1>
                <p>Un lugar donde aprender, jugar e interactuar se fusionan en una experiencia inmersiva y única. Únete a esta aventura y sé parte de una comunidad latina que busca expandir los horizontes del cosmos.</p>
                <button className="acces-metavers" onClick={handleNavigate}>Entrar al Metaverso</button>
            </section>

            <section className="two-elements font-home-page-section-two">
                <img className="bol-big" src={bol} />
                <img className="bol-small" src={bol} />
                <div className="noticia">
                    <div className="box-text-noticia">
                        <div className="text-content">
                            <h2>{noticias[currentIndex].tittleNoticia}</h2>
                            <p>{noticias[currentIndex].text}</p>
                        </div>
                        <button className="acces-metavers" onClick={handleNavigate}>Entrar al Metaverso</button>
                    </div>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination]}
                        className="mySwiper"
                        onSlideChange={handleSlideChange}
                    >
                        {noticias.map((noticia, index) => (
                            <SwiperSlide
                                key={noticia.id}
                            >
                                <img
                                className={index === currentIndex ? 'swiper-front' : ''}
                                src={noticia.img} alt={noticia.tittleNoticia} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
         </>
    );
}

const noticias = [];

/*
- Máximo 77 caracteres
- indicación IA; reduce a 77 caracteres incluyendo espacios y signos de puntuación:
 */
noticias.push({
    id: noticias.length,
    tittleNoticia: 'Bienvenido a Enycosmic Metavers',
    img: press1,
    text: 'Únete a Enycosmic y sé parte de una comunidad global que expande los horizontes del cosmos.',
});

noticias.push({
    id: noticias.length,
    tittleNoticia: 'Lanzamiento Enycosmic Metavers',
    img: press2,
    text: 'No te pierdas las novedades. Pronto lanzaremos el planetario virtual en el metaverso cósmico.',
});

noticias.push({
    id: noticias.length,
    tittleNoticia: 'Saca tu artista interior.',
    img: press3,
    text: 'Publica tu arte gratis en Enycosmic Metaver como músico, actor o escritor. Cupos limitados.',
});

noticias.push({
    id: noticias.length,
    tittleNoticia: 'Sé el primero en resolver los enigmas del cosmos.',
    img: press4,
    text: 'Sé pionero en el metaverso, resolviendo enigmas del cosmos y desbloqueando secretos del universo.',
});



export { HomePage };
