import React, { useEffect, useRef, useState } from 'react';
import './QuienesSomos.css';

import logo from './srcQuienesSomos/logoEnycosmic.png';
import jorge from './srcQuienesSomos/Jorge.png';
import jess from './srcQuienesSomos/Jess.png'

function QuienesSomos() {

/*Efecto estrellas (inicio) */
    const starContainerRef = useRef(null);

    useEffect(() => {
        const starContainer = starContainerRef.current;

        function createStar() {
            const star = document.createElement('div');
            star.classList.add('star');
            
            const size = Math.random() * 3 + 1 + 'px';
            star.style.width = size;
            star.style.height = size;
            
            const startX = Math.random() * window.innerWidth;
            const startY = Math.random() * window.innerHeight;
            star.style.left = startX + 'px';
            star.style.top = startY + 'px';
            
            const duration = Math.random() * 3 + 6 + 's'; // Duración entre 6s y 10s
            star.style.animationDuration = duration + ', ' + duration;
            
            starContainer.appendChild(star);
            
            setTimeout(() => {
                star.remove();
            }, parseFloat(duration) * 1000);
        }

        function generateStars() {
            const intervalId = setInterval(createStar, 120); // Genera una estrella cada 120ms

            return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
        }

        const cleanup = generateStars();

        return cleanup; // Ejecuta cleanup cuando el componente se desmonta
    }, []);
/*Efecto estrellas (fin) */

/*Section-two (inicio) */

const [selectedMember, setSelectedMember] = useState({
    name: 'Jorge Isaac',
    role: 'Desarrollador Full Stack',
    skills: ['JavaScript', 'Python', 'Angular', 'React', 'Node']
});

const handleMemberClick = (name, role, skills) => {
    setSelectedMember({ name, role, skills });
};

/*Section-two (fin) */

    return (
        <>
            <section className="two-elements quienesSomos-section-one">
                <div ref={starContainerRef} id="star-container">
                    <div className="box-text-quienesSomos">
                        <h1 className='tittle-shopp'>Quiénes Somos</h1>
                        <p>Somos una comunidad con una propuesta educativa en forma de metaverso que busca fortalecer la cultura, la ciencia, el arte y la creatividad, en un estilo planetario cósmico Pixel art.</p>
                        <p>Somos un mundo virtual que busca conectarte con el mundo real.</p>
                    </div>
                    <img className='logo-enycosmic-quienesSomos' src={logo} alt='Logo-Enycosmic' />
                </div>
            </section>

            <section className='two-elements quienesSomos-section-two'>
                <div className='description-equip'>
                    <h2>Nuestro Equipo</h2>
                    <h1 id='nameEquip'>{selectedMember.name}</h1>
                    <h2 id='workEquip'>{selectedMember.role}</h2>
                    <ul>
                        {selectedMember.skills.map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))}
                    </ul>
                </div>
                <div className='box-equipo'>
                    <img 
                        className='equipo-image-left' 
                        src={jorge} 
                        alt='imagen de equipo'
                        onClick={() => handleMemberClick('Jorge Isaac', 'Desarrollador Full Stack', ['JavaScript', 'Python', 'Angular', 'React', 'Node'])} 
                    />
                    <img 
                        className='equipo-image-right' 
                        src={jess} 
                        alt='imagen de equipo'
                        onClick={() => handleMemberClick('Jesica Glabor', 'Diseñadora Gráfica', ['Photoshop', 'Illustrator', 'Figma','After Effects' ,'XD'])}
                    />
                </div>
            </section>

            <section className='two-elements quienesSomos-section-three'>
                <div className='box-mision'>
                    <h2>Misión</h2>
                    <p>Enycosmic tiene como misión desarrollar un entorno seguro donde aprenderás jugando.</p>
                    <p>Además de jugar he interactuar, aprenderemos y aplicaremos conceptos de matemáticas, física, informática, arte y cultura en la vida real, de una manera diferente y divertida.</p>
                </div>
                <div className='box-mision'>
                    <h2>Visión</h2>
                    <p>En el año 2025 Enycosmic será reconocido como un metaverso educativo que revolucionó la educación.</p>
                    <p>Contaremos con más de 1200 usuarios activos y constantes en la plataforma.</p>
                </div>
            </section>
        </>
    );
}

export { QuienesSomos };
