import React from "react";
import discord from './srcForo/discord.png';
import './Foro.css';

function Foro() {
    return(
        <section className="foro one-element">
            <img className="discord" src={discord} />
            <h1 className='tittle-foro'>Súmate a nuestra comunidad y sé un Enycosmonauta</h1>
            <a className="acces-foro" href="https://discord.gg/wPcugEvF">Ir a Discord</a>
        </section>
    );
}

export { Foro };