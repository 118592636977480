import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import './ShoppingMetavers.css';

import tarjet1 from './srcShopp/tarjet-1.png';
import tarjet2 from './srcShopp/tarjet-2.png';
import tarjet3 from './srcShopp/tarjet-3.png';
import tarjet4 from './srcShopp/tarjet-4.png';

function ShoopingMetavers() {
    return (
        <section className="two-elements shopping">
                <div className="box-text-noticia">
                    <div className="text-content">
                        <h2>Adquiere tu código de acceso, participa y gana premios</h2>
                        <p>En nuestra plataforma desarrollamos servicios de enseñanza, y aprendizaje lúdico donde podrás aprender de una manera divertida y única, competir y ganar premios con nuestros cupones.</p>
                    </div>
                    <a href='https://seagreen-wombat-764177.hostingersite.com/' className="acces-shopping">Ir a EnyShop</a>
                </div>

                <div className='box-shop'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        }}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {tarjets.map(tarjet => (
                            <SwiperSlide key={tarjet.id}>
                                <img src={tarjet.img} alt={tarjet.src} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
        </section>
    );
}

const tarjets = [];

tarjets.push({
    id: tarjets.length,
    img: tarjet1,
    src: 'tarjeta shopping',
});
tarjets.push({
    id: tarjets.length,
    img: tarjet2,
    src: 'tarjeta shopping',
});
tarjets.push({
    id: tarjets.length,
    img: tarjet3,
    src: 'tarjeta shopping',
});
tarjets.push({
    id: tarjets.length,
    img: tarjet4,
    src: 'tarjeta shopping',
});

export { ShoopingMetavers };
